<template>
    <div class="home">
        <header class="center">
            <img src="@/assets/logo-white.svg" class="logo" />
        </header>

        <div class="body center">
            <h1>Sunny Banana</h1>
            <p class="center">
                Record your face, screen and voice, quickly and easily.<br />
                Perfect for professionals, students and you 🤩
            </p>
            <div class="cta-group">
                <w-button @click="getStarted" class="cta">Get started</w-button>
                <w-button
                    v-if="!currentUser"
                    @click="signin(() => $router.push('/'))"
                    background="#24292e"
                    class="sign-in"
                >
                    Sign in
                </w-button>
            </div>
        </div>
        <div class="body center features">
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rqmTXS5gpR0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </div>
        <div class="body center features">
            <div class="feature">
                <div class="topic">⭐️ 100% Free</div>
                <div class="description">
                    No registration or payment required
                </div>
            </div>
            <div class="feature">
                <div class="topic">🚀 Instant Recording</div>
                <div class="description">
                    No software downloads or extensions
                </div>
            </div>
            <div class="feature">
                <div class="topic">💾 Quick Download</div>
                <div class="description">
                    Save recordings to your computer within seconds
                </div>
            </div>
        </div>
        <div class="footer center">
            <p class="copyright">
                &copy; {{ new Date().getFullYear() }}
                <a href="http://w3pp.tech" target="_blank">w3pp.tech</a>. All
                rights reserved.
            </p>
        </div>
    </div>
</template>

<script>
import WButton from "../components/wButton.vue"
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()

import {
    getAuth,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
} from "firebase/auth"

export default {
    name: "Home",
    components: { WButton },
    data() {
        return {
            currentUser: null,
        }
    },
    mounted() {
        onAuthStateChanged(getAuth(), (user) => {
            this.currentUser = user
        })
    },
    methods: {
        signin(resolve) {
            signInWithPopup(getAuth(), new GoogleAuthProvider())
                .catch(() => {})
                .then(resolve)
        },
        signout(resolve) {
            signOut(getAuth())
                .catch(() => {})
                .then(resolve)
        },
        getStarted() {
            logEvent(analytics, "get started")
            this.$router.push("/record")
        },
    },
}
</script>
<style lang="scss" scoped>
.home {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    color: white;
    padding: 0 16px;
    display: flex;
    flex-direction: column;

    background-image: url("/img/star-bg.svg"),
        linear-gradient(#191c20, #24292e 320px);
    background-repeat: repeat-x;
    background-position: center 0, 0 0, 0 0;

    header {
        padding: 40px 36px;
        @media (max-width: 750px) {
            padding-left: 16px;
            padding-right: 16px;
        }
        .logo {
            width: 120px;
        }
    }

    .center {
        text-align: center;
    }

    .body {
        max-width: 1200px;

        padding: 0 36px;
        margin: 0 auto;

        @media (max-width: 750px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        h1 {
            font-size: 48px;
            font-weight: 300;
            line-height: 72px;
            margin-bottom: 16px;
        }
        p {
            font-size: 20px;
            font-weight: 300;
            color: #b2b3b5;
            margin-bottom: 24px;
            @media (min-width: 768px) {
                padding: 0 16px;
            }
        }
    }

    .features {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        @media (max-width: 830px) {
            flex-direction: column;
        }
        .feature {
            flex: 1;
            padding: 16px;
            min-height: 140px;
            .topic {
                font-size: 24px;
                margin-bottom: 16px;
            }

            .description {
                font-size: 16px;
                font-weight: 300;
                color: #b2b3b5;
            }
        }
    }
}

.cta-group {
    display: flex;
    justify-content: center;

    @media (max-width: 750px) {
        flex-direction: column;
    }
    .button {
        color: #fff;
        border-radius: 8px;
        font-size: 20px;
        height: 56px;
        font-weight: 500;
        margin: 16px;
        display: inline-flex;
        padding: 0 24px;

        &.cta {
            border: 1px solid rgba(27, 31, 35, 0.2);
            background-position: -1px -1px;
            background-size: 110% 110%;
            background-image: linear-gradient(to bottom, #3d74ff, #05309e);
        }
        &.sign-in {
            border: 1px solid rgba(254, 254, 254, 0.25);
            // background-image: linear-gradient(to bottom, #2d3339, #21262c);
        }
    }
}
iframe {
    max-width: 100%;
}

.footer {
    font-size: 14px;
    // font-weight: 300;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 80px 0 40px;
    color: #586069;

    a {
        color: #b2b3b5;
        &:not(:hover) {
            text-decoration: none;
        }
    }
}
</style>
